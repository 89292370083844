var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"transition":"scale-transition","close-on-content-click":false,"offset-y":"","content-class":_vm.datePickerWrapperClass,"max-width":"100%","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(!_vm.externalTrigger)?[(_vm.iconMode)?[_vm._t("icon-trigger",function(){return [_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"icon":"","large":"","color":"secondary","ripple":_vm.hasRipple},on:{"click":function($event){_vm.datePicker = true}}},[_c('v-icon',[_vm._v("mdi-calendar")])],1)],1)]},{"openDatePicker":_vm.openDatePicker})]:_c('div',{staticClass:"flex-1 date-picker-input",class:{ 'mb-4': !_vm.compactDisplay && !_vm.noMargins }},[(_vm.label)?_c('h3',{staticClass:"mr-4",class:{ 'text--disabled': _vm.disabled }},[_vm._v(_vm._s(_vm.label)+":")]):_vm._e(),(_vm.noInputMode)?_c('div',{staticClass:"d-flex justify-center align-content-center"},[_c('v-btn',{staticClass:"mt-4",attrs:{"ripple":_vm.hasRipple,"secondary":"","small":"","large":"","plain":"","disabled":_vm.disabled},on:{"click":function($event){_vm.datePicker = true}}},[_c('v-icon',[_vm._v("mdi-calendar")]),(_vm.inputValueDisplay)?_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.inputValueDisplay)+" ")]):_vm._e()],1)],1):_c('v-text-field',_vm._g(_vm._b({class:{
            required: _vm.required,
            'datepicker-with-label': Boolean(_vm.label),
            'ml-4': !_vm.displayFieldIcon && !_vm.noMargins,
            'mt-4': !_vm.noMargins,
            'mt-0': _vm.noMargins
          },attrs:{"dense":"","outlined":_vm.outlined,"readonly":"","height":_vm.height,"disabled":_vm.disabled,"error":_vm.error,"prepend-icon":_vm.displayFieldIcon && !_vm.innerIcon ? 'mdi-calendar' : '',"prepend-inner-icon":_vm.displayFieldIcon && _vm.innerIcon ? 'mdi-calendar' : '',"rules":_vm.validationRules,"required":_vm.required,"placeholder":_vm.placeholder,"hide-details":"auto","label":_vm.displayFieldLabel ? _vm.placeholder : '',"clearable":_vm.clearable},on:{"click:clear":function($event){_vm.isRange ? (_vm.selectedDates = []) : (_vm.selectedDates = '')},"blur":function($event){_vm.selectedDates = _vm.parseDates(_vm.formattedDates)}},model:{value:(_vm.inputValueDisplay),callback:function ($$v) {_vm.inputValueDisplay=$$v},expression:"inputValueDisplay"}},'v-text-field',attrs,false),on))],1)]:[_vm._t("external-trigger",null,{"openDatePicker":_vm.openDatePicker})]]}}],null,true),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{ref:"datePicker",staticClass:"date-picker",attrs:{"min":_vm.minDate,"max":_vm.maxDate,"allowed-dates":_vm.allowedDates,"no-title":_vm.noTitle,"color":_vm.color,"range":_vm.isRange},on:{"update:allowedDates":function($event){_vm.allowedDates=$event},"update:allowed-dates":function($event){_vm.allowedDates=$event},"update:picker-date":yearMonth => _vm.$emit('date-navigation', `${yearMonth}-15`),"input":_vm.handleDatesSelected},model:{value:(_vm.selectedDates),callback:function ($$v) {_vm.selectedDates=$$v},expression:"selectedDates"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <form-base :header="header" hide-required-legend>
      <template #form>
        <v-form ref="form" :class="{ disabled: readOnly }">
          <v-container class="elevation-1 px-8 pt-8" fluid>
            <h3 class="pb-5">Basic Details</h3>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <text-field
                  :disabled="readOnly"
                  :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"
                  :required="true"
                  type="text"
                  v-model="editingWarehouse.name"
                  :rules="$validator.rules.required('Warehouse Name')"
                  tooltipText="Name of Warehouse location visible to Warehouse and Carriers"
                  label="Warehouse Name"></text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <phone-number-field
                  :disabled="readOnly"
                  :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"
                  margin-top="-7px"
                  v-model="editingWarehouse.phone"
                  :validator="$validator"
                  :default-country="defaultPhoneCountry"
                  tooltipText="Warehouse contact phone number for Carriers"
                  label="Phone"></phone-number-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <text-field
                  :disabled="readOnly"
                  :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"
                  type="email"
                  v-model="editingWarehouse.email"
                  tooltipText="Warehouse contact email for Carriers. It will be used as 'Reply To' address of the appointment emails."
                  :rules="$validator.rules.email"
                  label="Warehouse Contact Email"></text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <text-field
                  :disabled="readOnly"
                  :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"
                  prepend-icon="mdi-pound"
                  v-model="editingWarehouse.facilityNumber"
                  tooltipText="Warehouse store number"
                  label="Store Number"></text-field>
              </v-col>
            </v-row>
            <v-row class="mb-4">
              <v-col class="align-field" cols="12" sm="6" md="4">
                <country-select
                  :disabled="readOnly"
                  v-model="editingAddress.country"
                  limit-options
                  tooltipText="Country of the Warehouse location"
                  :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"></country-select>
              </v-col>
              <v-col :class="{ 'align-field': manualAddressMode }">
                <address-autocomplete-field
                  required
                  ref="searchAddressField"
                  v-if="!manualAddressMode"
                  clear-on-country-change
                  v-model="geocodingData"
                  :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"
                  :disabled="!editingAddress.country || readOnly"
                  :country="editingAddress.country"
                  tooltipText="Address of the Warehouse location visible to carriers"
                  @filled="fillFullAddress"
                  @manual-override="enterManualAddressMode"></address-autocomplete-field>
                <street-field
                  v-else
                  ref="manualStreetField"
                  @search="enterSearchAddressMode"
                  required
                  v-model="editingAddress.street"
                  :rules="$validator.rules.required('Street Address')" />
              </v-col>
            </v-row>

            <address-display
              v-if="!manualAddressMode && editingAddress.city"
              :zip="editingAddress.zip"
              :city="editingAddress.city"
              :state="editingAddress.state"
              :street="editingAddress.street"
              :timezone="editingAddress.timezone" />

            <v-row v-else-if="manualAddressMode">
              <v-col cols="12" sm="6" md="3">
                <text-field
                  required
                  type="text"
                  v-model="editingAddress.city"
                  :rules="$validator.rules.required('City')"
                  label="City"></text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <state-select
                  v-if="editingAddress.country === 'USA' || editingAddress.country === 'CAN'"
                  required
                  :rules="$validator.rules.required('State')"
                  label="State"
                  :country="editingAddress.country"
                  v-model="editingAddress.state"></state-select>
                <text-field
                  v-else
                  required
                  type="text"
                  v-model="editingAddress.state"
                  :rules="$validator.rules.required('State')"
                  label="State"></text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <text-field
                  required
                  type="text"
                  v-model="editingAddress.zip"
                  :rules="$validator.rules.required('Zip')"
                  placeholder="85331"
                  label="Zip / Postal Code"></text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <timezone-select
                  v-model="editingAddress.timezone"
                  :rules="$validator.rules.required('Timezone')"
                  label="Timezone"
                  required />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <email-list-field
                  class="full-width mb-4"
                  :read-only="!$rolePermissions.canUpdateWarehouse || readOnly"
                  :disabled="readOnly"
                  label="Default Email Subscribers"
                  prepend-icon="mdi-email-outline"
                  v-model="editingWarehouse.ccEmails"
                  tooltipText="Default email subscribers for appointments at this facility."
                  small-chips
                  small></email-list-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea
                  v-if="$rolePermissions.canUpdateWarehouse && !readOnly"
                  rows="2"
                  outlined
                  v-model="editingWarehouse.notes">
                  <template v-slot:label>
                    <div class="with-tooltip">
                      Notes
                      <help-icon-tooltip iconStyleClass="grey darken-2">
                        Notes that are internal to the Warehouse
                      </help-icon-tooltip>
                    </div>
                  </template>
                </v-textarea>
                <div v-else class="v-input v-text-field">
                  <div class="v-text-field__slot full-width">
                    <label class="v-label font-size-x-small">Notes</label>
                    <div class="text--disabled border-width-1 bordered rounded pa-4 full-width">
                      {{ editingWarehouse.notes }}
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <rich-text-input
                  v-if="$rolePermissions.canUpdateWarehouse && !readOnly"
                  @ready="handleRichTextReady"
                  v-model="editingWarehouse.instructions"
                  tooltipText="To be displayed in the email notifications and the Carrier View Portal"
                  label="Instructions for Carriers"></rich-text-input>
                <div v-else class="v-input v-text-field">
                  <div class="v-text-field__slot full-width">
                    <label class="v-label font-size-x-small">Instructions</label>
                    <div
                      class="text--disabled border-width-1 bordered rounded pa-4"
                      v-html="editingWarehouse.instructions"></div>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <warehouse-amenities-ppe-requirements
                  :fields="editingWarehouse"></warehouse-amenities-ppe-requirements>
              </v-col>
            </v-row>

            <v-row class="mt-9">
              <v-col>
                <h3 class="black--text">RefNumber (PO Number) Validation Setup</h3>
                <a
                  href="https://community.loadsmart.com/hc/en-us/articles/14946368437907-PO-Ref-Number-Validation-Implementation"
                  target="_blank"
                  rel="noopener"
                  class="link">
                  (Learn more here)
                </a>
              </v-col>
            </v-row>

            <v-row class="mt-6">
              <v-col cols="6">
                <v-select
                  :disabled="readOnly"
                  :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"
                  outlined
                  persistent-hint
                  persistent-placeholder
                  label="Version"
                  v-model="editingWarehouse.refNumberValidationVersion"
                  :items="refNumberValidationVersionItems"></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  :disabled="readOnly"
                  :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"
                  spellcheck="false"
                  type="url"
                  v-model="editingWarehouse.refNumberValidationUrl"
                  :rules="$validator.rules.url"
                  label="Validator URL"></v-text-field>
              </v-col>
            </v-row>

            <v-row
              v-if="
                editingWarehouse.refNumberValidationVersion ===
                novaCore.RefNumberValidationVersion.V2
              ">
              <v-col>
                <v-text-field
                  :disabled="readOnly"
                  :readonly="!$rolePermissions.canUpdateWarehouse || readOnly"
                  spellcheck="false"
                  type="text"
                  v-model="editingWarehouse.refNumberValidationPasscode"
                  label="Passcode (optional Auth-Bearer token)"></v-text-field>
              </v-col>
            </v-row>

            <v-divider class="mt-6"></v-divider>

            <h3 class="pb-2" v-if="false">Tags</h3>

            <v-row v-if="false">
              <v-col>
                <tag-manager
                  :disabled="readOnly"
                  :read-only="!$rolePermissions.canUpdateWarehouse || readOnly"
                  height="60px"
                  v-model="editingWarehouse.tags"
                  label="Warehouse Tags"></tag-manager>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h3 class="mt-16 mb-2 black--text">Scheduling Portal Availability</h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <carrier-scheduling-toggle
                  class="pl-2 pb-2"
                  entity="Warehouse"
                  :disabled="!$rolePermissions.canUpdateWarehouse || readOnly"
                  v-model="editingWarehouse.allowCarrierScheduling"></carrier-scheduling-toggle>
              </v-col>
            </v-row>
            <v-row
              v-if="
                editingWarehouse.allowCarrierScheduling &&
                $carrierViewAllowedUserRoles.includes($me.role) &&
                !readOnly
              ">
              <v-col>
                <div class="d-flex">
                  <primary-button
                    small
                    test-id="copy-warehouse-button"
                    before-icon="link"
                    class="mr-4"
                    @click="copyWarehouseLink">
                    Copy warehouse link to share
                  </primary-button>
                  <preview-as-carrier-button
                    :warehouse="warehouse"
                    :disabled="!warehouse.allowCarrierScheduling"></preview-as-carrier-button>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h3 class="mt-10 mb-2 black--text">Experimental Feature</h3>
                <div class="mt-5">
                  <p>
                    <caution-message>
                      This changes Carrier availability - make sure you understand the implications:
                    </caution-message>
                    Prevent surprise appointments by removing the first
                    <text-field
                      v-model="editingWarehouse.intervalTrimForCarriers"
                      ref="intervalTrimForCarriers"
                      class="inline-text-field no-spinner text-center"
                      type="number"></text-field>
                    <strong>intervals</strong>
                    from Carrier availability. "Interval" in this context refers to a continuous
                    block of Warehouse open-hours.
                  </p>
                </div>
              </v-col>
            </v-row>

            <v-divider class="mt-5"></v-divider>

            <v-row>
              <v-col cols="12">
                <h3 class="mt-16 mb-2 black--text">Warehouse Settings</h3>
              </v-col>
              <v-col cols="12">
                <settings-expansion-panels
                  :read-only="readOnly"
                  :entity-parent="novaCore.DatabaseEntities.Org"
                  :entity-key="novaCore.DatabaseEntities.Warehouse"
                  :should-be-expanded="false"
                  :entity-parent-obj="editingWarehouse"
                  @update-settings="data => (warehouseSettings = data)"
                  :entity-settings="warehouse?.settings || {}"></settings-expansion-panels>
              </v-col>
            </v-row>

            <action-group
              class="pr-0 pt-8"
              confirm-icon="upload"
              confirm-label="Save Warehouse"
              :hide-cancel="true"
              cancel-color="error"
              :confirm-button-tooltip="readOnly ? novaCore.getRoleActionError() : ''"
              :disable-confirm="!$rolePermissions.canUpdateWarehouse || readOnly"
              @confirm="submit"></action-group>
          </v-container>
        </v-form>
      </template>
    </form-base>
    <v-dialog
      :value="Boolean(notificationConfigKey)"
      :retain-focus="false"
      width="auto"
      @keydown.esc="notificationConfigKey = null">
      <template v-slot:default>
        <div class="my-2">
          <notification-config-form
            v-if="notificationConfigKey"
            :warehouse-id="warehouse.id"
            :settings="warehouse.settings || {}"
            :notification-type="notificationConfigKey"></notification-config-form>
        </div>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import autocompleteAddressMixin from '@/components/mixins/autocompleteAddressMixin';
import config from '@/config/config';
import { differenceWith, isEqual } from 'lodash';

/**
 * Edit Warehouse Form
 * @displayName Edit Warehouse Form
 */
export default {
  name: 'EditWarehouseForm',
  mixins: [autocompleteAddressMixin],
  props: {
    /**
     * Form Header
     */
    header: {
      type: String,
      required: false
    },
    /**
     * Warehouse Object
     */
    warehouse: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      originalWarehouseData: null,
      editingWarehouse: {},
      warehouseSettings: {},
      notificationConfigKey: null,
      refNumberValidationVersionItems: [
        {
          text: 'Version 2',
          value: 'V2'
        },
        {
          text: 'Version 1 / Google Sheet',
          value: 'V1'
        }
      ],
      richTextInputReady: false
    };
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let settings = {};
      let data = { ...this.editingWarehouse, ...this.editingAddress };
      // Make sure this setting is sent as an integer
      data.intervalTrimForCarriers = Number(data.intervalTrimForCarriers);
      const updatedAttributes = this.getUpdatedAttributes(this.editingWarehouse, this.warehouse);

      // Remove settings that have no value
      if (this.editingWarehouse.settings) {
        settings = { ...this.editingWarehouse.settings };
        Object.entries(settings).map(([key, value]) => {
          if (!value && value !== 0 && value !== false) {
            delete settings[key];
          }

          if (key === 'muteAppointmentNotifications' && typeof settings[key] === 'boolean') {
            settings[key] = !settings[key];
          }
        });
      }

      try {
        const response = await this.$store.dispatch('Warehouses/updateWarehouse', {
          ...data,
          settings,
          updatedAttributes
        });
        if (response?.data?.data) {
          this.$eventHub.$emit('update-Warehouse', response.data.data);
        }
      } finally {
        this.$emit('close');
      }
    },
    updateDirtyStatus() {
      const editingData = { ...this.editingWarehouse, ...this.editingAddress };
      const originalData = this.originalWarehouseData;

      const diff = differenceWith([editingData], [originalData], isEqual);

      const hasChanged = diff.length > 0;
      this.setDirtyStatus(this.richTextInputReady && hasChanged);
    },
    /**
     * Set warehouse being edited
     */
    setEditingWarehouse() {
      this.editingWarehouse = {
        ...this.warehouse,
        notes: this.warehouse.notes || '',
        instructions: this.warehouse.instructions || '',
        refNumberValidationUrl: this.warehouse.refNumberValidationUrl || null,
        tags: this.warehouse.tags || [],
        ccEmails: this.warehouse.ccEmails || []
      };

      this.editingWarehouse.settings = this.warehouseSettings;

      this.editingAddress = {
        country: this.warehouse.country,
        state: this.warehouse.state,
        city: this.warehouse.city,
        zip: this.warehouse.zip,
        street: this.warehouse.street,
        timezone: this.warehouse.timezone
      };
      this.$nextTick(() => {
        this.originalWarehouseData = { ...this.editingWarehouse };
        this.updateDirtyStatus();
      });
    },
    handleRichTextReady() {
      this.setEditingWarehouse();
      this.richTextInputReady = true;
    },
    getUpdatedAttributes(curObj, baseObj) {
      let updatedAttributes = [];

      for (const attribute in curObj) {
        if (attribute === 'settings') {
          updatedAttributes = [
            ...updatedAttributes.concat(
              this.getUpdatedAttributes(curObj[attribute], baseObj[attribute])
            )
          ];
        } else {
          const fieldHasChanged = !baseObj
            ? true
            : attribute === 'muteAppointmentNotifications'
            ? this.$route.meta.isDirty &&
              JSON.stringify(curObj[attribute]) !== JSON.stringify(!baseObj[attribute])
            : attribute !== 'gateManagementAdditionalInformationText'
            ? JSON.stringify(curObj[attribute]) !== JSON.stringify(baseObj[attribute])
            : false;
          if (fieldHasChanged) {
            updatedAttributes.push(this.novaCore.breakWordsAtCaps(attribute).toLowerCase());
          }
        }
      }
      if (
        JSON.stringify(this.editingAddress) !==
        JSON.stringify({
          country: this.warehouse.country,
          state: this.warehouse.state,
          city: this.warehouse.city,
          zip: this.warehouse.zip,
          street: this.warehouse.street,
          timezone: this.warehouse.timezone
        })
      ) {
        updatedAttributes.push('address');
      }
      return [...new Set(updatedAttributes)];
    },
    async copyWarehouseLink() {
      await this.$copyContent(
        `${config.luna_url}/warehouses/${this.warehouse?.id}`,
        'Warehouse link copied to clipboard'
      );
    }
  },
  watch: {
    warehouse: {
      handler() {
        this.setEditingWarehouse();
      },
      deep: true
    },
    warehouseSettings: {
      handler() {
        this.editingWarehouse.settings = this.warehouseSettings;
      },
      deep: true
    },
    editingWarehouse: {
      handler() {
        // This is needed so that Neutron clears it out when the UI text field
        // is cleared.
        if (!this.editingWarehouse.refNumberValidationUrl) {
          this.editingWarehouse.refNumberValidationUrl = null;
        }
        this.updateDirtyStatus();
      },
      deep: true
    },
    editingAddress: {
      handler() {
        this.updateDirtyStatus();
      },
      deep: true
    },
    '$route.name': function () {
      this.setEditingWarehouse();
    }
  },
  mounted() {
    this.setEditingWarehouse();
    this.$eventHub.$on('set-edit-notification-key', notificationKey => {
      this.notificationConfigKey = notificationKey;
    });
  },
  destroyed() {
    this.$eventHub.$off('set-edit-notification-key');
  }
};
</script>

<style scoped>
.align-field {
  padding-top: 7px !important;
}

.link {
  font-weight: normal !important;
  font-size: 14px;
}

p {
  line-height: 0;
}
</style>

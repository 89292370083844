<template>
  <div class="full-height" v-if="appointmentWithWarehouse?.warehouse?.id">
    <section class="sidebar-content">
      <div class="sidebar">
        <v-tabs v-model="tab" vertical :centered="false" hide-slider @change="handleTabChange">
          <v-tab key="status">Status & Details</v-tab>
          <v-tab key="claims_support" :disabled="claimSupportTriggers.length === 0">
            Claims Support
          </v-tab>
          <v-tab key="gate_management">Gate Management</v-tab>
          <v-tab key="drivers_chat">Drivers Chat</v-tab>
          <v-tab key="audit_log">Audit Log</v-tab>
        </v-tabs>
      </div>
      <div class="content" v-if="appointmentWithWarehouse?.id">
        <v-tabs-items v-model="tab">
          <v-tab-item key="details" :transition="false">
            <appointment-details-status
              v-if="tab === 0"
              v-bind="$props"
              :appointment="appointmentWithWarehouse" />
          </v-tab-item>
          <v-tab-item key="claims_support" :transition="false">
            <tab-content-centered>
              <template #content>
                <appointment-details-claims
                  v-if="tab === 1"
                  v-bind="$props"
                  :appointment="appointmentWithWarehouse" />
              </template>
            </tab-content-centered>
          </v-tab-item>
          <v-tab-item key="gate_management" :transition="false">
            <tab-content-centered>
              <template #content>
                <appointment-details-gate-management
                  v-if="tab === 2"
                  :triggers="gateManagementTriggers"
                  :appointment="appointmentWithWarehouse" />
              </template>
            </tab-content-centered>
          </v-tab-item>
          <v-tab-item key="drivers_chat" :transition="false"></v-tab-item>
          <v-tab-item key="audit_log" :transition="false" @click="trackApptDetailsMixpanelEvent">
            <tab-content-centered class="audit-log-tab-wrapper">
              <template #content>
                <appointment-details-audit-log v-if="tab === 4" v-bind="$props" />
              </template>
            </tab-content-centered>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </section>
  </div>
</template>

<script>
import { computed, nextTick, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import warehouseService from '@satellite/services/warehouse-service';
import {
  useAppointmentDetailsHelpers,
  useCustomForms,
  useEventHub,
  useNovaCore
} from '@/composables';
import { appointmentHelpersProps } from '@/composables/useAppointmentHelpers';
import { appointmentDetailsHelpersProps } from '@/composables/useAppointmentDetailsHelpers';
import { useRouter } from 'vue-router/composables';

/**
 * @displayName Appointment Details
 */
export default {
  props: {
    ...appointmentHelpersProps,
    ...appointmentDetailsHelpersProps
  },
  setup(props) {
    const novaCore = useNovaCore();
    const eventHub = useEventHub();
    const { claimSupportTriggers, gateManagementTriggers, trackApptDetailsMixpanelEvent } =
      useAppointmentDetailsHelpers(props.appointment, props.appointmentWarehouse);
    const customForms = useCustomForms();
    const router = useRouter();
    const tab = ref(0);
    const warehouse = ref(null);

    const appointmentWithWarehouse = computed(() => {
      return {
        ...props.appointment,
        warehouse: warehouse.value
      };
    });

    const handleTabChange = async tab => {
      const currentQuery = { ...router.currentRoute.query };
      currentQuery.appointmentId = props.appointment.id;
      currentQuery.tab = tab;
      await nextTick();
      router.push({ query: currentQuery });
    };

    watch(
      () => props.appointment?.id,
      async () => {
        warehouse.value = {
          ...props.appointmentWarehouse,
          ...(await warehouseService.getWarehouseById(props.appointment.dock.warehouseId, {}))
        };
      },
      { immediate: true }
    );

    onBeforeMount(() => {
      eventHub.$on('action-field-submitted', customForms.handleActionFieldTriggered);
      if (router.currentRoute.query.tab) {
        tab.value = parseInt(router.currentRoute.query.tab);
      }
      trackApptDetailsMixpanelEvent();
    });

    onBeforeUnmount(() => {
      eventHub.$off('action-field-submitted', customForms.handleActionFieldTriggered);
    });

    return {
      tab,
      appointmentWithWarehouse,
      novaCore,
      claimSupportTriggers,
      gateManagementTriggers,
      trackApptDetailsMixpanelEvent,
      handleTabChange
    };
  }
};
</script>

<style scoped lang="scss">
.sidebar-content {
  display: flex;
  min-height: 100%;

  > .content {
    padding: 24px;
    flex: 1;
    background-color: $color-background-transparency-inverted;
  }
}

.sidebar {
  border-right: 1px solid $color-line-divider;
}

.v-tabs {
  position: sticky;
  top: 0;
}

.v-tab {
  font-size: 12px;
  height: auto;
  padding: 16px 12px;
  border-bottom: 2px solid $color-line-divider;
  font-weight: 600;

  &--active {
    color: $color-primary-100;
    border-bottom: 2px solid $color-primary-60;
  }
}

.v-window-item {
  background-color: rgba(146, 146, 146, 0.12);
}

::v-deep .audit-log-tab-wrapper {
  &.content {
    background-color: #fff;
    padding: 24px;
  }
}

::v-deep {
  .v-v-input__append-inner {
    margin-top: 8px !important;
  }

  .v-select.v-text-field:not(.v-text-field--single-line) input {
    margin-top: 0;
  }
}
</style>

<style lang="scss">
.v-input--dense .v-input__icon {
  height: 24px !important;
}

.v-application .v-text-field__slot {
  margin-top: 0 !important;
}

.v-text-field {
  padding-top: 0;
  margin-top: 0;
}

.vue-tel-input-vuetify {
  .v-input__slot {
    min-height: 40px;
  }

  .v-select__selections {
    height: 40px;
  }

  label {
    line-height: 12px;
  }

  .country-code {
    width: 50px !important;

    .v-input__slot {
      width: 50px !important;
    }
    .v-select__selections {
      justify-content: center;
    }
    .vti__flag {
      margin-right: 0;
    }
  }
  > div:first-child {
    fieldset {
      border-radius: 5px 0 0 5px;
    }
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-text-field__details {
      display: none;
    }
  }

  > div:not(:first-child) {
    fieldset {
      border-left: 0;
      border-radius: 0 5px 5px 0;
    }
  }
}
</style>
